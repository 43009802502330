import webApiClient from '@tunein/web-api-client';
import { pick } from '@tunein/web-utils';
import isEmpty from 'lodash/isEmpty';
import appConfig from 'src/common/config';
import {
  CLIENT_API_RETRY_LIMIT,
  CLIENT_API_TIMEOUT,
} from 'src/common/constants/experiments/config';
import {
  UNIFIED_EVENTS_BATCH_INTERVAL_SECS,
  UNIFIED_EVENTS_MAX_BATCH_COUNT,
} from 'src/common/constants/experiments/reporting';
import getConfigStreamFormats from 'src/common/utils/getConfigStreamFormats';
import { configApiOptions } from '../../common/config/configApiOptions';
import { getBaseApiConfig } from '../../common/config/utils';
import {
  PARTNER_USERNAME,
  PARTNER_USERNAME_EXTERNAL_DEMO,
} from '../../common/constants/experiments/partners';
import { FORCE_HTTPS_STREAMS } from '../../common/constants/experiments/player';
import { getGuildIdQueryParam } from '../../common/utils/discord';
import { isDevBuild } from '../../common/utils/environment';
import vars from '../../common/vars';

export default function createTuneInApiClient(initialState = {}) {
  const { auth, config, app = {} } = initialState;
  const authTokens = pick(auth?.tokenInfo, ['accessToken', 'refreshToken']);
  const rmConfigs = config?.autoupdate || {};
  const {
    isMobile,
    isDesktop,
    isDiscord,
    isFord,
    isFordSubdomain,
    desktopVersion,
    tuneInUserSerial,
    partnerId,
    userAgent,
  } = app;

  const apiConfig = {
    ...getBaseApiConfig(isDiscord, isFord),
    configApiOptions: {
      ...configApiOptions,
      ...(isFord && { commands: appConfig.fordConfigCommands }),
    },
    debug: isDevBuild(),
    partnerId,
    serial: tuneInUserSerial,
    timeout: rmConfigs[CLIENT_API_TIMEOUT],
    retryLimit: rmConfigs[CLIENT_API_RETRY_LIMIT],
    reportingIntervalDelaySecs: rmConfigs[UNIFIED_EVENTS_BATCH_INTERVAL_SECS],
    reportingMaxBatch: rmConfigs[UNIFIED_EVENTS_MAX_BATCH_COUNT],
    forceHttpsStreams: rmConfigs[FORCE_HTTPS_STREAMS],
    streamFormats: getConfigStreamFormats(
      isMobile,
      isDesktop,
      isDiscord,
      userAgent,
    ),
    revCatApiKey: vars.get('REVENUE_CAT_PUBLIC_API_KEY'),
    ...(desktopVersion && { desktopVersion }),
    ...(isDiscord && { productMode: getGuildIdQueryParam() }),
    ...(isFord && {
      username:
        rmConfigs[
          isFordSubdomain ? PARTNER_USERNAME_EXTERNAL_DEMO : PARTNER_USERNAME
        ],
    }),
    graphQlTransformAllowlist: { getUser: true },
  };

  const requestHeaders = {
    // TODO: disabled per TUNE-17244 - revisiting this when it won't cause our URLs to be translated
    // ...(acceptLanguageHeader && { 'Accept-Language': acceptLanguageHeader }),
  };
  const client = webApiClient(apiConfig, requestHeaders);

  if (!isEmpty(authTokens)) {
    client.setTokens(authTokens);
  }

  return client;
}
